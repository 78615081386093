import { styled } from "@mui/system";

const PageWrap = styled("div")(({ theme }) => ({
  "&": {
    margin: "auto",
    maxWidth: 1920,
    width: `calc(100% - ${theme.spacing(4)})`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(30)})`,
    },
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${theme.spacing(40)})`,
    },
    [theme.breakpoints.up("lg")]: {
      width: `calc(100% - ${theme.spacing(50)})`,
    },
    [theme.breakpoints.up("xl")]: {
      width: `calc(100% - ${theme.spacing(70)})`,
    },
  },
}));

export default PageWrap;
