import { ReactNode, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

type InViewDetectorProps = {
  children: (ref: (node?: Element) => void, isViewed: boolean) => ReactNode;
  retriggerOnView?: boolean;
};

const InViewDetector = ({
  children,
  retriggerOnView = false,
}: InViewDetectorProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [isViewed, setIsViewed] = useState(false);

  useEffect(() => {
    if (retriggerOnView) {
      setIsViewed(inView);
    } else {
      if (inView && !isViewed) {
        setIsViewed(true);
      }
    }
  }, [inView, isViewed, retriggerOnView]);

  return <>{children(ref, isViewed)}</>;
};

export default InViewDetector;
