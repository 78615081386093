import { getImage } from "gatsby-plugin-image";
import { isNil } from "lodash";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { buildLink } from "../../utils/link/buildLink";

export type MainLink = { label: string; slug: string };
export type LayoutData = GatsbyTypes.SiteTitleQueryQuery;

export const LayoutDataContext = createContext<LayoutData | undefined>(
  undefined
);

type LayoutDataProps = {
  data: LayoutData;
  children: ReactNode;
};

const LayoutDataProvider = ({ data, children }: LayoutDataProps) => (
  <LayoutDataContext.Provider value={data}>
    {children}
  </LayoutDataContext.Provider>
);

export const useLayoutDataContext = () => {
  const context = useContext<LayoutData>(LayoutDataContext);
  if (isNil(context)) {
    console.info("LayoutDataContext must be used within a LayoutDataProvider");
  }

  const logoImage = context.prismicLayout.data.logo.localFile.publicURL;

  const mainLinks = useMemo(
    () =>
      context.prismicLayout?.data?.main_links?.map(({ label, link }) => ({
        label,
        slug: buildLink(link),
      })),
    [context.prismicLayout?.data?.main_links]
  );

  const iconLinks = useMemo(
    () =>
      context.prismicLayout?.data?.icon_links?.map(({ image, link }) => ({
        image: getImage(image.localFile.childImageSharp.gatsbyImageData),
        slug: buildLink(link),
      })),
    [context.prismicLayout?.data?.icon_links]
  );

  return {
    logoImage: logoImage,
    mainLinks,
    iconLinks,
    companyDescription: context.prismicLayout?.data?.company_description?.html,
    contactInfo: context.prismicLayout?.data?.contact_information?.html,
  };
};

export default LayoutDataProvider;
