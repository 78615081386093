import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { PageProps } from "gatsby";
import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { ImageData } from "../../../utils/image/buildImageData";
import Image from "../../elements/Image/Image";
import Link from "../../elements/Link/Link";

const MotionBox = motion(Box);

type SideLinksProps = {
  links: SideLinksLink[];
  location: PageProps["location"];
};

type SideLinksLink = {
  link: string;
  image: ImageData;
};

const vhToPixel = (value: number) =>
  !isNil(window) ? (window.innerHeight * value) / 100 : 0;

const SideLinks = ({ links, location }: SideLinksProps) => {
  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrolledPixels = vhToPixel(95);

      if (
        window.scrollY > Number(scrolledPixels) ||
        location.pathname === "/contact"
      ) {
        setShowLinks(true);
      } else {
        setShowLinks(false);
      }
    };

    if (!isNil(window)) {
      window.addEventListener("scroll", scrollHandler);
    }

    return () => window.removeEventListener("scroll", scrollHandler);
  }, [location.pathname]);

  useEffect(() => {
    setShowLinks(false);
  }, [location.pathname]);

  return (
    <AnimatePresence exitBeforeEnter>
      {showLinks && (
        <MotionBox
          sx={{
            position: "sticky",
            left: 0,
            top: "50%",
            zIndex: 10,
            height: "60vh",
            width: "0.1px",
            display: {
              xs: "none",
              sm: "block",
            },
            overflow: "visible",
          }}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
        >
          <Grid
            container
            alignContent="center"
            rowSpacing={0.5}
            sx={{
              width: "50px",
              transform: "translate(0, -50%)",
            }}
          >
            {links.map(({ image, link }) => (
              <Grid item key={link} xs={12}>
                <Link to={link}>
                  <Image alt={image.alt} image={image.image} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default SideLinks;
