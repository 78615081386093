exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-property-form-tsx": () => import("./../../../src/templates/propertyForm.tsx" /* webpackChunkName: "component---src-templates-property-form-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-we-are-tsx": () => import("./../../../src/templates/weAre.tsx" /* webpackChunkName: "component---src-templates-we-are-tsx" */)
}

