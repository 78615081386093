import Markdown from "markdown-to-jsx";
import Button from "../button/Button";
import Text, { TextProps } from "../Text/Text";
import Link from "../Link/Link";
import { useMemo } from "react";
import { isNil } from "lodash";
import { SxProps } from "@mui/material";

export type RichTextProps = {
  text: string;
  textProps?: TextProps;
};

const RichText = ({ text, textProps }: RichTextProps) => {
  const textPropsProps = useMemo(() => {
    if (!isNil(textProps)) {
      return {
        ...textProps,
        sx: {
          hyphens: "auto",
          overflowWrap: "break-word",
          ...(textProps?.sx as SxProps),
        },
      };
    }

    return { sx: { hyphens: "auto", overflowWrap: "break-word" } };
  }, [textProps]);

  return (
    <Markdown
      options={{
        overrides: {
          h1: {
            component: Text,
            props: {
              variant: "h1",
              ...textPropsProps,
            },
          },
          h2: {
            component: Text,
            props: {
              variant: "h2",
              ...textPropsProps,
            },
          },
          h3: {
            component: Text,
            props: {
              variant: "h3",
              ...textPropsProps,
            },
          },
          h4: {
            component: Text,
            props: {
              variant: "h4",
              ...textProps,
            },
          },
          h5: {
            component: Text,
            props: {
              variant: "h5",
              ...textProps,
            },
          },
          h6: {
            component: Text,
            props: {
              variant: "h6",
              ...textProps,
            },
          },
          p: {
            component: Text,
            props: {
              variant: "body1",
              ...textProps,
            },
          },
          span: {
            component: Text,
            props: {
              variant: "body1",
              ...textProps,
            },
          },
          button: {
            component: Button,
          },
          a: {
            component: Link,
          },
        },
      }}
    >
      {text}
    </Markdown>
  );
};

export default RichText;
