import { Typography, TypographyProps, TypographyTypeMap } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";

export type TextProps<
  C extends ElementType = TypographyTypeMap["defaultComponent"],
  P = Record<string, unknown>
> = Omit<TypographyProps<C, P>, "component"> & {
  component?: C;
};

const StyledTypography = styled(Typography)({});

const Text = <C extends ElementType = TypographyTypeMap["defaultComponent"]>({
  variant,
  component,
  ...restProps
}: TextProps<C, { component?: C }>) => {
  return (
    <StyledTypography variant={variant} component={component} {...restProps} />
  );
};

export default Text;
