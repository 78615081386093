import { Button as MuiButton } from "gatsby-theme-material-ui";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";

export type ButtonProps = MuiButtonProps;

const Button = ({
  children,
  variant = "contained",
  href,
  ...props
}: ButtonProps) => {
  return (
    <MuiButton variant={variant} to={href} {...props}>
      {children}
    </MuiButton>
  );
};

export default Button;
