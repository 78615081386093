import { Box, BoxProps } from "@mui/material";
import { useLayoutDataContext } from "../../../providers/useLayout";

type LogoPorps = {
  className?: string;
  sx?: BoxProps["sx"];
};

const Logo = ({ className, sx }: LogoPorps) => {
  const data = useLayoutDataContext();

  return (
    <Box className={className} sx={sx}>
      <img alt="logo" src={data.logoImage} />
    </Box>
  );
};

export default Logo;
