import { Grid } from "@mui/material";
import React from "react";
import { MainLink, useLayoutDataContext } from "../../../providers/useLayout";
import { styled } from "@mui/material/styles";
import Link from "../../elements/Link/Link";
import Logo from "../../elements/Logo/Logo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import RichText from "../../elements/RichText/RichText";
import Image from "../../elements/Image/Image";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import { isNil } from "lodash";

const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  width: "fit-content",
  [theme.breakpoints.up("sm")]: {
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
}));

const LogoStyled = styled(Logo)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    alignSelf: "center",
  },
  "& img": {
    filter: "brightness(0) invert(1)",
    maxHeight: theme.spacing(9),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: theme.spacing(11),
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: theme.spacing(13),
    },
  },
}));

const FooterStyled = styled(Grid)(({ theme }) => ({
  background: "linear-gradient(to left, #015b97, #28a6e1)",
  padding: theme.spacing(5, 2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
}));

const FooterContent = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

type FooterLinksProps = {
  mainLinks: MainLink[];
};

const FooterLinks = ({ mainLinks }: FooterLinksProps) => (
  <InViewAnimationGrid
    container
    direction="column"
    sx={{ maxWidth: "fit-content" }}
    item
    sm={4}
  >
    {mainLinks?.map(({ label, slug }) => (
      <LinkStyled key={label} to={slug} underline="hover" variant="h4">
        {label}
      </LinkStyled>
    ))}
  </InViewAnimationGrid>
);

type FooterContactInfoProps = {
  icons: {
    image: IGatsbyImageData;
    slug: string;
  }[];
  contactInfo: string;
};

const FooterContactInfo = ({ icons, contactInfo }: FooterContactInfoProps) => (
  <InViewAnimationGrid
    container
    direction="column"
    sx={{ maxWidth: "fit-content" }}
    item
    sm={4}
  >
    <Grid item sx={{ marginLeft: "auto" }}>
      {!isNil(contactInfo) && (
        <RichText
          textProps={{
            mb: 0,
            color: (theme) => theme.palette.common.white,
            maxWidth: "fit-content",
            sx: {
              "& *": {
                color: (theme) => theme.palette.common.white,
                textDecoration: "none",
              },
            },
          }}
          text={contactInfo}
        />
      )}
      <Grid sx={{ mt: !isNil(contactInfo) ? 2 : 0 }}>
        {icons?.map(({ image, slug }, index) => (
          <Link
            sx={{
              "& img": {
                maxHeight: "36px",
                width: "auto",
                filter: "brightness(0) invert(1)",
              },
              mr: 1,
            }}
            key={index}
            to={slug}
          >
            <Image alt="image" image={image} />
          </Link>
        ))}
      </Grid>
    </Grid>
  </InViewAnimationGrid>
);

const Footer = () => {
  const data = useLayoutDataContext();
  return (
    <FooterStyled>
      <FooterContent>
        <FooterLinks mainLinks={data?.mainLinks} />
        <Grid item sm={3} md={2}>
          <Link to="/">
            <LogoStyled />
          </Link>
        </Grid>

        <FooterContactInfo
          icons={data?.iconLinks}
          contactInfo={data?.contactInfo}
        />
      </FooterContent>
    </FooterStyled>
  );
};

export default Footer;
