import { useCallback, useState, useEffect } from "react";
import { AppBar, Drawer, Grid, Toolbar, useTheme } from "@mui/material";
import { IconButton } from "gatsby-theme-material-ui";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import Link from "../../elements/Link/Link";
import RichText from "../../elements/RichText/RichText";
import Logo from "../../elements/Logo/Logo";
import CookieConsent from "react-cookie-consent";

import { styled } from "@mui/material/styles";
import { MainLink, useLayoutDataContext } from "../../../providers/useLayout";
import { IGatsbyImageData } from "gatsby-plugin-image";
import Image from "../../elements/Image/Image";
import InViewAnimationGrid from "../../behaviors/InViewAnimation/InViewAnimationGrid";
import { useWindowScrollPosition } from "rooks";
import Button from "../../elements/button/Button";
import Text from "../../elements/Text/Text";
import { useConsentDataContext } from "../../../providers/useConsent";
import { isNil } from "lodash";

type MenuContentPops = {
  items: MainLink[];
  description: string;
  icons: {
    image: IGatsbyImageData;
    slug: string;
  }[];
  iconsTitle: string;
};

const MenuContentContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2),
  zIndex: -1,
  marginTop: theme.spacing(10),
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(11),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(12),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: theme.spacing(13),
    paddingLeft: theme.spacing(24),
    paddingRight: theme.spacing(24),
  },
}));

const MenuItem = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

const MenuContent = ({
  items,
  description,
  icons,
  iconsTitle,
}: MenuContentPops) => {
  return (
    <MenuContentContainer container>
      <InViewAnimationGrid repeatOnView container direction="column">
        {items?.map(({ label, slug }) => (
          <MenuItem key={label} to={slug} underline="hover" variant="h3">
            {label}
          </MenuItem>
        ))}
      </InViewAnimationGrid>
      <InViewAnimationGrid repeatOnView container>
        <Grid item xs={12}>
          <Grid item xs={10}>
            {!isNil(description) && (
              <RichText
                textProps={{
                  mb: 0,
                  color: "secondary",
                }}
                text={description}
              />
            )}
          </Grid>
        </Grid>
        <Grid item sx={{ maxWidth: "fit-content", marginLeft: "auto" }}>
          <RichText
            textProps={{
              mb: 1,
              ml: 1,
              color: "secondary",
            }}
            text={iconsTitle}
          />

          <Grid>
            {icons?.map(({ image, slug }) => (
              <Link
                sx={{ "& img": { maxHeight: "36px", width: "auto" }, ml: 1 }}
                key={slug}
                href={slug}
              >
                <Image alt="image" image={image} />
              </Link>
            ))}
          </Grid>
        </Grid>
      </InViewAnimationGrid>
    </MenuContentContainer>
  );
};

const LogoStyled = styled(Logo)(({ theme }) => ({
  "& img": {
    maxHeight: theme.spacing(6),
    [theme.breakpoints.up("sm")]: {
      maxHeight: theme.spacing(7),
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: theme.spacing(8),
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: theme.spacing(9),
    },
  },
}));

const Menu = () => {
  const [open, setOpen] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const { setAccepted: setConsentAccepted } = useConsentDataContext();

  const { scrollY } = useWindowScrollPosition();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const data = useLayoutDataContext();

  useEffect(() => {
    if (scrollY > 10) {
      setIsTransparent(false);
    } else {
      setIsTransparent(true);
    }
  }, [scrollY]);

  const theme = useTheme();

  const handleConsentAccept = useCallback(() => {
    setConsentAccepted(true);
  }, [setConsentAccepted]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          transition: "background-color 0.3s",
          backgroundColor: (theme) =>
            isTransparent
              ? `${theme.palette.common.white}00`
              : theme.palette.common.white,
          "@supports (backdrop-filter: blur(5px))": {
            backgroundColor: (theme) =>
              isTransparent
                ? `${theme.palette.common.white}00`
                : `${theme.palette.common.white}32`,
            backdropFilter: isTransparent ? "unset" : "blur(5px)",
          },
        }}
      >
        <Toolbar
          sx={{
            p: 2,
            paddingLeft: {
              xs: 2,
              sm: 4,
              md: 6,
            },
            paddingRight: {
              xs: 2,
              sm: 4,
              md: 6,
            },
          }}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Link to="/">
              <LogoStyled />
            </Link>

            <IconButton
              aria-label="menu"
              sx={{ height: "fit-content" }}
              onClick={!open ? handleOpen : handleClose}
            >
              {!open ? <MenuIcon /> : <CloseIcon />}
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        onClick={handleClose}
        disableScrollLock
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: (theme) => theme.palette.common.white,
          },
          zIndex: 1099,
        }}
        anchor="top"
        open={open}
      >
        <MenuContent
          items={data?.mainLinks}
          icons={data?.iconLinks}
          description={data?.companyDescription}
          iconsTitle="FOLGE SIE UNS"
        />
      </Drawer>
      <CookieConsent
        style={{ background: theme.palette.common.white, color: "unset" }}
        ButtonComponent={Button}
        buttonStyle={{
          color: theme.palette.common.white,
          background: theme.palette.primary.main,
          borderRadius: theme.spacing(0.5),
        }}
        buttonText="Ich verstehe"
        onAccept={handleConsentAccept}
      >
        <Text variant="body1" sx={{ marginBottom: 0 }}>
          Diese Website verwendet Cookies, um die Benutzererfahrung zu
          verbessern.
        </Text>
      </CookieConsent>
    </>
  );
};

export default Menu;
