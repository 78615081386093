import { Link as GatsbyLink } from "gatsby-material-ui-components";
import { ComponentProps } from "react";

export type LinkProps = ComponentProps<typeof GatsbyLink>;

const Link = (props: LinkProps) => {
  return <GatsbyLink {...props} />;
};

export default Link;
