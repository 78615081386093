import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { isNil } from "lodash";

export type ImageData = {
  image: IGatsbyImageData;
} & {
  alt: string;
};

export const buildImageData = (
  prismicImage: GatsbyTypes.LandingPageQuery["prismicLandingPage"]["data"]["hero"][0]["mobile_image"]
) => {
  if (isNil(prismicImage)) {
    return {
      image: null,
      alt: null,
    };
  }
  const { localFile, ...rest } = prismicImage;
  const image = getImage(localFile?.childImageSharp?.gatsbyImageData);

  const imageData = {
    image,
    ...rest,
  };

  return imageData;
};
