import { isNil } from "lodash";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Cookies } from "react-cookie-consent";

export type ConsentData = {
  accepted: boolean;
  setAccepted: Dispatch<SetStateAction<boolean>>;
};

export const ConsentDataContext = createContext<ConsentData | undefined>(
  undefined
);

type ConsentDataProps = {
  children: ReactNode;
};

const ConsentDataProvider = ({ children }: ConsentDataProps) => {
  const [accepted, setAccepted] = useState<boolean>(
    Cookies.get("CookieConsent")
  );

  return (
    <ConsentDataContext.Provider value={{ accepted, setAccepted }}>
      {children}
    </ConsentDataContext.Provider>
  );
};

export const useConsentDataContext = () => {
  const context = useContext<ConsentData>(ConsentDataContext);
  if (isNil(context)) {
    console.info(
      "ConsentDataContext must be used within a ConsentDataProvider"
    );
  }

  return context;
};

export default ConsentDataProvider;
