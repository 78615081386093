import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const UnderConstruction = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <StaticImage
        style={{
          objectFit: "contain",
        }}
        alt="under construction"
        src="../../../images/under-construction.jpg"
      />
    </Box>
  );
};

export default UnderConstruction;
