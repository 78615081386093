import { Components, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Shadows } from "@mui/material/styles/shadows";
import { ShapeOptions } from "@mui/system";

export const columnSpacing = { xs: 2, sm: 3 };

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#22222",
      dark: "#22222",
      main: "#22222",
    },
    secondary: {
      light: "#0c5e96",
      dark: "#0c5e96",
      main: "#0c5e96",
    },
    background: {
      default: "#0c5e9605",
      paper: "#1D3557",
    },
    common: {
      white: "#ffffff",
      black: "#000000",
    },
  },
});

const typography: TypographyOptions = {
  allVariants: {
    color: theme.palette.primary.main,
  },
  fontFamily: [
    "Varela Round",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontSize: theme.spacing(5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.spacing(7),
      marginBottom: theme.spacing(4),
    },
  },
  h2: {
    fontSize: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(5),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.spacing(6),
    },
  },
  h3: {
    fontSize: theme.spacing(3.5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(4),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.spacing(4.5),
    },
  },
  h4: {
    fontSize: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.spacing(3.5),
    },
  },
  body1: {
    marginBottom: theme.spacing(2),
    fontSize: theme.spacing(2),
  },
};

const shadows: Shadows = [
  "none",
  `0px 0px 4px 2px ${theme.palette.primary.main}10`,
  `0px 0px 4px 2px ${theme.palette.secondary.main}80`,
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
  "none",
];

const shape: ShapeOptions = {
  borderRadius: 0,
};

const components: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        padding: theme.spacing(2, 6),
        boxShadow: theme.shadows[0],
      },
      contained: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
        textDecorationColor: theme.palette.secondary.main,
        "&:hover": {
          opacity: 0.6,
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.secondary.main,
      },
      fontSizeLarge: {
        fontSize: "48px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        marginBottom: 0,
      },
    },
  },
};

theme = createTheme(theme, {
  shadows,
  shape,
  components,
  typography,
});

export default theme;
