import { useStaticQuery, graphql, PageProps } from "gatsby";

import { ThemeProvider } from "@mui/material";
import PageWrap from "../components/modules/PageWrap/PageWrap";
import { ReactElement } from "react";
import LayoutDataProvider from "../providers/useLayout";
import Menu from "../components/modules/Menu/Menu";
import Footer from "../components/modules/Footer/Footer";
import CMSSideLinks from "../components/cms/SideLinks/CMSSideLinks";
import theme from "../theme/Theme";
import ConsentDataProvider from "../providers/useConsent";
import { Box } from "@mui/system";
import UnderConstruction from "../components/views/UnderConstruction/UnderConstruction";

type LayoutProps = {
  children: ReactElement[];
  location: PageProps["location"];
};

const Layout = ({ children, location }: LayoutProps) => {
  const data = useStaticQuery<GatsbyTypes.LayoutQueryQuery>(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }

      prismicLayout {
        data {
          logo {
            localFile {
              publicURL
            }
            alt
          }

          icon_links {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                  id
                }
              }
            }
            link {
              document {
                ... on PrismicLandingPage {
                  url
                }
                ... on PrismicCompanyPage {
                  url
                }
                ... on PrismicProjectsPage {
                  url
                }
                ... on PrismicServicesPage {
                  url
                }
                ... on PrismicWeArePage {
                  url
                }
                ... on PrismicContactPage {
                  url
                }
                ... on PrismicPropertyFormPage {
                  url
                }
              }
              url
              localFile {
                publicURL
              }
            }
          }

          main_links {
            label
            link {
              document {
                ... on PrismicLandingPage {
                  url
                }
                ... on PrismicCompanyPage {
                  url
                }
                ... on PrismicProjectsPage {
                  url
                }
                ... on PrismicServicesPage {
                  url
                }
                ... on PrismicWeArePage {
                  url
                }
                ... on PrismicContactPage {
                  url
                }
                ... on PrismicPropertyFormPage {
                  url
                }
              }
              url
              localFile {
                publicURL
              }
            }
          }

          company_description {
            html
          }
          contact_information {
            html
          }
          side_icon_links {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            link {
              document {
                ... on PrismicLandingPage {
                  url
                }
                ... on PrismicCompanyPage {
                  url
                }
                ... on PrismicProjectsPage {
                  url
                }
                ... on PrismicServicesPage {
                  url
                }
                ... on PrismicWeArePage {
                  url
                }
                ... on PrismicContactPage {
                  url
                }
                ... on PrismicPropertyFormPage {
                  url
                }
              }
              url
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={theme}>
      <LayoutDataProvider data={data}>
        <ConsentDataProvider>
          {process.env.NODE_ENV === "development" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Menu />
              <CMSSideLinks
                location={location}
                data={data.prismicLayout.data.side_icon_links}
              />
              <PageWrap>
                <main>{children}</main>
              </PageWrap>
              <Footer />
            </Box>
          ) : (
            <UnderConstruction />
          )}
        </ConsentDataProvider>
      </LayoutDataProvider>
    </ThemeProvider>
  );
};

export default Layout;
