import { Grid, GridProps } from "@mui/material";
import { motion } from "framer-motion";
import InViewDetector from "../InViewDetector/InViewDetector";

type InViewAnimationGridProps = Omit<
  GridProps,
  "onDrag" | "onDragStart" | "onDragEnd" | "onAnimationStart"
> & {
  repeatOnView?: boolean;
};

const MotionGrid = motion(Grid, { forwardMotionProps: true });

const InViewAnimationGrid = ({
  children,
  repeatOnView = false,
  ...restProps
}: InViewAnimationGridProps) => {
  return (
    <InViewDetector retriggerOnView={repeatOnView}>
      {(ref, isViewed) => {
        return (
          <MotionGrid
            style={{ opacity: 0 }}
            animate={{ opacity: isViewed ? 1 : 0 }}
            transition={{ delay: 0.3 }}
            {...restProps}
            ref={ref}
          >
            {children}
          </MotionGrid>
        );
      }}
    </InViewDetector>
  );
};

export default InViewAnimationGrid;
